.MainBrowseVideo {
	position: relative;
	height: calc(100vh - 120px);
	display: flex;
	overflow-y: scroll;
	flex-flow: row nowrap;
	margin: 0% 2%;
}
.BrowseVideoCard {
	width: 90%;
	margin: 0% 5%;
	margin-left: 0%;
	scroll-behavior: auto;
	scroll-snap-align: center;
	scroll-snap-type: both mandatory;
}

.browse-wrapper {
	background-color: rgba(229, 229, 229, 0.2);
	height: 100vh;
	/* position: relative; */
	/* top: 100px; */
	padding: 2.5vw;
	font-family: Montserrat;
	/* box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25); */
}
.browse-wrapper h1 {
	margin-top: 80px;
	text-align: left;
	font-weight: 600;
	font-size: 15px;
	color: #666666;
}
#browseBtns {
	display: flex;
	justify-content: space-between;
	width: 95vw;
	margin: 0 auto;
	align-items: center;
	padding-top: 28px;
}
#videoBrowseBtn {
	/* padding: 25px; */
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	height: 25vh;
	width: 49%;
	display: flex;
	justify-content: center;
	align-items: center;
}
#videoBrowseBtn a img {
	margin: 0 auto;
}
#videoBrowseBtn a p {
	font-weight: 600;
	font-size: 10px;
	color: #34529e;
	margin-top: 10px;
}
#musicProfessionalBtn {
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	height: 25vh;
	width: 49%;
	display: flex;
	justify-content: center;
	align-items: center;
}
#musicProfessionalBtn a img {
	margin: 0 auto;
}
#musicProfessionalBtn a p {
	font-weight: 600;
	font-size: 10px;
	color: #34529e;
	margin-top: 10px;
}
#gigsBrowseBtn {
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	height: 15vh;
	/* width: 49%; */
	display: flex;
	justify-content: center;
	align-items: center;
}
#gigsBrowseBtn a img {
	margin: 0 auto;
}
#gigsBrowseBtn a p {
	font-weight: 600;
	font-size: 10px;
	color: #34529e;
	margin-top: 10px;
}
.b {
	height: 100vh;
}
#VideoBrowseContent {
	height: 100vh;
	width: 200vw;
	position: absolute;
}
#browseVidWrapper {
	/* display: flex; */
	justify-content: center;
	/* align-items: center; */
	overflow: hidden;
}
.css-t2klrc div {
	height: 100% !important;
}
.awssld__content div {
	height: 100% !important;
	/* width: 100vw !important; */
}
.css-djit9u {
	height: 100% !important;
}
#VideoBrowseName {
	z-index: 1;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	text-align: left;

	color: #ffffff;
}
.carousel-status {
	display: none !important;
}
.control-arrow {
	display: none !important;
}
#personInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* height: 58px !important; */
	/* width: 60vw !important; */
	/* background-color: transparent; */
	/* justify-content: space-around; */
	position: absolute;
	/* margin-top: 62px; */
	z-index: 1;
	/* margin-bottom: 20px; */
	top: 21px;
	left: 15px;
}
#personInfo a {
	display: flex;
	width: 101%;
	align-items: center;
	justify-content: space-around;
}
#personInfo img {
	border-radius: 114px;
	width: 35px;
	height: 35px;
	margin-right: 7px;
	/* margin-left: 35vw; */
}
#personInfo #personProf {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 8px;
	line-height: 10px;
	/* identical to box height */

	color: #ffffff;
}
#videoBrowseInnerWrapper {
	height: 100vh;
}
.awssld {
	margin-top: 50px;
}
#VideoBrowse {
	height: calc(100vh - 61px - 68px);
	padding: 0;
	position: relative;
	/* width: 90vw; */
}
.videoBrowsReact {
	position: absolute;
	bottom: 0;
}
