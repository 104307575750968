#chatHead {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 10px 0;
}
#chats {
	/* padding-top: 51px; */
}
#chat {
	border-top: gray 1px solid;
	border-bottom: gray 1px solid;
	/* margin-top : -1px */
	padding: 6px 12px;
	background-color: white;
}

#chat {
	display: flex;
	align-items: center;
	/* justify-content: ; */
}
#chat .profilePhoto,
.messageList .profilePhoto {
	background-position: center;
	width: 51px;
	height: 51px;
	background-size: cover;
	border-radius: 50px;
}
#chat .nameMsg {
	width: calc(100% - 51px - 12px);
	text-align: left;
	padding-left: 15px;
}
#chat .nameMsg .name {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#chat .nameMsg h6 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	text-align: center;
}
#chat .nameMsg .name {
	font-family: Montserrat;
	margin-bottom: 7px;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	color: #34529e;
}
#chat .nameMsg .msg {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 12px;
	text-align: left;
}
#chat .nameMsg .msg.unRead {
	color: #34529e;
}
.msgHeader {
	display: flex;
	align-items: center;
	z-index: 99;
}
.msgHeader {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	padding: 7px 16px;
}
.messageList {
	height: 100vh;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: space-between;
}
.messageList .status {
	display: flex;
	align-items: center;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	color: rgba(52, 82, 158, 0.3);
}
.messageList .nameMsg {
	display: flex;
	align-items: center;
}
.messageList .nameMsg .profilePhoto {
	margin-right: 15px;
}
.messageList .name {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;

	color: #000000;
}
.messageList .status svg {
	margin-right: 6px;
}
.messageList .backArrow {
	margin-right: 20px;
}
.messageList .msgBox {
	display: flex;
	align-items: center;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	padding: 14px 12px;
}
.messageList .msgBox .innerMsgBox {
	display: flex;
	background: #ffffff;
	border: 1px solid #dfdfdf;
	box-sizing: border-box;
	border-radius: 5px;
	width: 100%;
	align-items: center;
}
.messageList .msgBox .attachment {
	width: 30px;
	height: 30px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid #dfdfdf;
}

.messageList .msgBox .attachment input {
	position: absolute;
	width: 30px;
	height: 30px;
	top: 0px;
	left: 0px;
	opacity: 0;
}
.messageList .msgBox .msgType {
	padding: 13px 10px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 12px;
	/* text-align: center; */
	color: #000000;
	outline: none;
	width: calc(100% - 50px);
}
.messageList .msgBox .sendSvg {
	margin-right: 5px;
}

.messageList .chatBody {
	height: calc(100vh - 65px - 68px);
	background: #f6f8fb;
	overflow-y: auto;
	overflow-x: hidden;
}
.messageList .chatBody .innerChatBot {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: baseline;
	padding: 20px 12px;
}
.messageList .chatBody .innerChatBot .msgWhen {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	width: 100%;
	color: rgba(52, 82, 158, 0.4);
	margin-bottom: 62px;
}
.messageList .chatBody .innerChatBot .msgBubble {
	border: 1px solid rgba(52, 82, 158, 0.5);
	box-sizing: border-box;
	padding: 12px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	margin-bottom: 5px;
	text-align: left;
	max-width: 52%;
	/* width: fit-content; */
	height: 100%;
	overflow: visible;
}
.messageList .chatBody .innerChatBot .msgBubble.inComing {
	border-radius: 0px 7px 7px 7px;
	background: #f6f8fb;
	color: #000000;
}
.messageList .chatBody .innerChatBot .msgBubble.outGoing {
	background: #34529e;
	border-radius: 7px 0px 7px 7px;
	color: white;
	/* margin-right: 0px; */
	/* margin-left: auto; */
}
.messageList .chatBody .msgBubbleDiv {
	width: 100%;
	margin: 5px 0px;
}
.messageList .chatBody .msgBubbleDiv.outGoing {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.messageList .chatBody .msgBubbleDiv.outGoing svg {
	margin-right: 10px;
}
/* .messageList .msgBox .attachment input[type=file]::file-selector-button , .messageList .msgBox .attachment input[type=file]: {
    display: none;
  } */

.NewChat .newChatHeader {
	background: #34529e;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	text-align: center;

	color: #ffffff;
	display: flex;
	align-items: center;
	padding: 15px 13px;
}
.NewChat .newChatHeader svg {
	margin-right: 15px;
}
.NewChat .chatTo {
	width: 100%;
	border: none;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	/* text-align: center; */
	outline: none;

	color: #666666;
	border-bottom: 1px solid #d3d3d3;
	padding: 11px 15px;
}
.NewChat .chatPeople {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	display: flex;
	/* align-items: center; */
	padding: 3px;
	height: 50px;
	margin-bottom: 10px;
}
.NewChat .suggestList {
	padding: 0px 12px;
}
.NewChat .suggestList h5 {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	text-align: left;

	color: #000000;
	margin: 15px 16px 11px;
}
.NewChat .suggestList .personName {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	text-align: left;

	color: #252525;
}
.NewChat .suggestList .personPro {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	line-height: 11px;
	/* identical to box height */

	text-align: center;
	color: #666666;
}
.NewChat .suggestList .nameProf {
	padding: 5px 10px;
}
.NewChat .chatPeople .profilePhoto {
	background-position: center;
	width: 38px;
	height: 38px;
	background-size: cover;
	border-radius: 50px;
}
