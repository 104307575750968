#landingbg {
    width: 100%;
    position: fixed;
    background-image: url('../../../assets/images/login/Loginbg.png');
    height: 100%;
}
#overlay {
    position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(52, 82, 158, 0.9);
}
/* #logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40vw;
}
#landing-logo {
    position: absolute;
    width: 44vw;
    height: 40vw;
    margin-top: 2vh ;
} */
#logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 31vw;
}
#landing-logo {
    position: absolute;
    width: 28vw;
    height: 28vw;
    margin-top: 2vh;
}
#landing-heading {
    color: white;
    width: 80vw;
    margin: 0 auto;
    position: relative;
    top: 10vh
}
#landing-heading h1 {
    font-size: 12vw;
}
#landing-btn-wrapper { 
    position: absolute;
    bottom: 10vh;
    left: 5vw;
}
#landing-btn-wrapper button{
    display: block;
    margin: 0 auto;
    width: 90vw;
    color: #34529E;
    background-color: white;
    border: 1px solid white;
    margin-top: 26px;
    height: 40px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    border-radius: 3px; 
}