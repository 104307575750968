#commentSystemWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px
}
#commentSystemWrap input{
    border: 1px solid #B7B7B7;
    border-radius: 3px 0px 0px 3px;
    width: 79%;
}
#commentSystemWrap button {
    color: white;
    background-color: #34529E;
    padding: 14px 20px;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 0px 3px 3px 0px;
}