.Model {
	.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
		width: 500px;
		height: auto;
		padding: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;
		text-align: center;
	}
	.Heading {
		font-size: 40px;
		line-height: 45px;
		text-align: center;
		color: #1252a3;
		font-weight: 600;
		margin-top: 50px;
	}
	.Text {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-weight: 500;
		line-height: 1.2;
		font-size: 1.25rem;
	}
}
