@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap");

.SignupSignupDesktop {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	padding: 2% 100px;
	overflow: hidden;
}
.SignupHeader {
	width: 100%;
	overflow: hidden;
}
.SignupRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	overflow: hidden;
}
.SignupSectionOne {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: center;
	justify-content: flex-start;
	width: 50%;
	margin: 5% 2%;
	overflow: hidden;
}
.SignupHeading {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 40px;
	line-height: 30px;
	text-align: center;
	color: #34529e;
}
.SignupHeadingTwo {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	color: #474747;
	margin: 22px 0%;
}
.SignupSectionOneImage {
	width: 100%;
	height: 100%;
}

.SignupSectionTwo {
	width: 50%;
	margin: 0% 2%;
}
.SignupForm {
	width: 100%;
	padding: 5%;
	background-color: #fff;
	border-radius: 5px;
}
.SignupLabel {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 15px;
	line-height: 30px;
	text-align: left;
	color: #474747;
	width: 100%;
	margin-top: 10px;
}

.margin {
	margin: 15px 0px;
	width: 100%;
}

.SignupInputSignUp {
	width: 100%;
	height: 60px;
	border: 1px solid #c1c1c1;
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: 600;
	font-style: normal;
	font-size: 17px;
	line-height: 17px;
	text-align: left;
	color: #474747;
	padding: 15px 26px;
}
.SignupInputSignUp:focus {
	border: 0px;
}
.SignupForgot {
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	text-align: right;
	color: #34529e;
	margin-bottom: 5px;
}
.SignupContinueButton {
	width: 100%;
	height: 60px;
	background: #34529e;
	border-radius: 5px;
	color: #fff;
	font-family: "Josefin Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
}

.SignupSignup {
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	color: #818181;
	margin-bottom: 30px;
}
.SignupNewAccount {
	color: #34529e;
	font-weight: 700;
}
