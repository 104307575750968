.profession-wrapper h2{
    font-family: Montserrat;
    font-weight: 600;
    font-size: 15px;
    width: 70%;
    margin: 0 auto;
}
.profession-wrapper h4 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    margin-top: 27px;
}
#limitlabel {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 12px;
}
#limit-label {
    font-family: Montserrat;
    font-weight: 300;
    font-size: 12px;
    color: #B22222;
}
#limitlabel span {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 12px;
    margin-left: 0;
    color: darkred;
}
#completeProfile-img {
    width: 38vw;
    margin: 0 auto;
    padding-top: 8px
}
#checkboxes-wrapper {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-left: 1%;
}
#checkboxes-wrapper {
    margin-top: 20px;
}

.checkbox-wrapper {
    padding: 4px 6px;
}  

.checkbox-wrapper input {
    position: absolute;
    left: -9999px;
}
  
.checkbox-wrapper div label {
display: flex;
align-items: center;
justify-content: center;
position: relative;
height: 40px;;
font-size: 10px;
color: #666666;
background-color: #E6E2E2;
white-space: nowrap;
cursor: pointer;
user-select: none;
transition: background-color .2s, box-shadow .2s;
border-radius: 3px;
}

.checkbox-wrapper div label::before {
content: '';
display: block;
position: absolute;
transition: background-color .2s;
}

.checkbox-wrapper input:checked + div label {
background-color: #B3995B;
color: white;
}

.checkbox-wrapper input:checked + div label::before {
background-color: #fff;
}


#cPNextBtn{
    color: #34529E;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 12px;
    float: right;
    right: 5vw;
    position: absolute;
    bottom: 2vh;
    background: transparent;
}
#back-Icon {
    position: absolute;
    top: 2vh;
    left: 3vw;
}

#cPCompleteBtn {
    background: #34529E;
    box-shadow: 0px 10px 30px rgba(52, 82, 158, 0.25);
    border-radius: 5px;
    width: 95%;
    height: 40px;
    margin-top: 27px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
}