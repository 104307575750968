.ShareVideo {
  height: 100vh;
  .countdown {
    position: fixed;
    right: 80px;
    top: 80px;
    font-size: xx-large;
    color: white;
    font-weight: 500;
  }
  .eDDpY {
    // top: 0% !important;
    bottom: -25%;
  }
  .durationTime {
    bottom: 4%;
  }
  .cmCyBs {
    display: none;
  }
  .render-actions__ActionsWrapper-dp6lnv-0 {
    bottom: -24%;
  }
}
