.loaderReset {
	border: 4px solid #fff;
	border-radius: 50%;
	border-top: 4px solid #000;
	width: 20px;
	height: 20px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}
#reset-password-wrapper {
	height: 100%;
	font-family: Montserrat;
}
#reset-logo-wrapper {
	width: 100%;
	height: 58px;
	margin-top: 20px;
	display: flex;
	justify-content: center;
}
#reset-heading {
	width: 85vw;
	margin: 0 auto;
}
#reset-heading h1 {
	font-weight: 600;
	font-size: 20px;
	margin-top: 7vh;
}
#reset-heading p {
	font-weight: normal;
	font-size: 12px;
	margin-top: 8vh;
}
#reset-input #login-email {
	border: 1px solid #ababab;
	border-radius: 2px;
	width: 90vw;
	color: #34529e;
	font-size: 11px;
}
#reset-input p {
	text-align: left;
	left: 5vw;
	position: relative;
	color: black;
	/* font-family: Montserrat; */
	font-weight: 600;
	font-size: 12px;
	margin-top: 7vh;
	margin-bottom: 7px;
}
#login-input input {
	border: 1px solid #ababab;
	border-radius: 2px;
}
#reset-btn-wrapper button {
	display: flex;
	margin: 0 auto;
	width: 90vw;
	color: #ffffff;
	border: 1px solid white;
	background: #34529e;
	margin-top: 5vh;
	height: 40px;
	border-radius: 3px;
	font-weight: bold;
	font-size: 15px;
	justify-content: center;
	text-align: center;
}
#reset-btn-wrapper button {
	outline: none;
}

#reset-btn-wrapper a p {
	margin-top: 12px;
}
#reset-input-wrapper {
	margin-top: 5vh;
}
#reset-password-input:focus {
	outline: none;
}
#reset-password-input {
	width: 90vw;
	height: 50px;
	margin-top: 4vh;
	padding: 10px;
	border: 1px solid #ababab;
	border-radius: 2px;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	color: #ababab;
}
