#premiumWrapper img {
  margin: 0 auto;
  width: 59.77px;
  padding-top: 41px;
  padding-bottom: 29px;
}
#premiumPlans {
  background-color: #fff;
  margin: 0 auto;
  width: 94vw;
  border-radius: 5px;
  padding: 17px;
}
#premiumWrapper {
  background: #e5e5e5;
  /* height: 100vh; */
}
#premiumPlanHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #34529e;
}
#premiumHeadSubline {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  margin-top: 4px;
  color: #959595;
}
#premiumHeadPricing {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  text-align: left;
  color: #000000;
  margin-bottom: 31px;
}
#premiumHeadPricing span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 37px;
  text-align: left;
  color: #959595;
}
#premiumHeadPriceWrapper {
  margin-top: 24px;
  margin-bottom: 22px;
}
#premiumWhatYouGet {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #cececd;
  border-radius: 4px;
  padding: 16px 99px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #5f5f5f;
}
#premiumUl li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: #3d3d3d;
  margin-bottom: 16px;
}
#premiumUl li img {
  width: 15px;
  margin: unset;
  padding: unset;
  margin-right: 8px;
}
#premiumPayBtnsWrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 29px;
}
.premiumFirstBtn {
  width: 50%;
  height: 60px;
  background: #e45e13;
  border-radius: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
.premiumSecondBtn {
  width: 50%;
  height: 60px;
  background: #110c4d;
  border-radius: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}
#premiumCustomPlan {
  background-color: #fff;
  margin: 0 auto;
  width: 94vw;
  border-radius: 5px;
  padding: 17px;
  margin-top: 12px;
}
#premiumCustomHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #d69b2b;
}
#premiumCustomSub {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  margin-top: 4px;
  color: #959595;
}
#premiumContactWrapper {
  margin-top: 33px;
}
#premiumContactHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  text-align: left;
}
#premiumContactEmail {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: left;
  color: #959595;
  margin-top: 9px;
}
#premiumSendEmailBtn {
  padding: 16px 72px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #d69b2b;
  border: 1px solid #d69b2b;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 32px;
}

/* PAYMENT SUCCESS CSS START */
#pSuccessWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}
#pSuccessWrapper h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #34529e;
  margin-top: 51px;
}
#pSuccessWrapper p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  color: #797979;
  margin-top: 13px;
  margin-bottom: 106px;
}
#pSuccessWrapper a {
  padding: 10px 148px;
  background: #34529e;
  border-radius: 5px;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
}
.premFaq {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  background: #34529e;
  width: 100%;
  padding: 12px 0px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.premFaqAcc,
.premFaqAcc.Mui-expanded {
  margin: 10px 13px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(103, 103, 103, 0.25);
  border-radius: 2px;
  text-align: left;
}
