@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700");

.ProfessionGenreUpdateDesktop {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	padding: 2% 100px;
	overflow: hidden;
}
.ProfessionHeader {
	width: 100%;
	overflow: hidden;
}
.ProfessionProfessionRow {
	display: flex;
	flex-direction: row;
	/* align-items: center;
	align-content: center;
	justify-content: center; */
	width: 100%;
	overflow: hidden;
}
.ProfessionSectionOneProfession {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
	width: 50%;
	margin: 5% 2%;
	height: auto;
	overflow: hidden;
}
.ProfessionHeading {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 35px;
	line-height: 40px;
	text-align: center;
	color: #34529e;
}
.ProfessionSectionOneImage {
	width: 100%;
	height: 100%;
}

.ProfessionSectionTwoProfession {
	width: 50%;
	margin: 5% 2%;
}

.ProfessionHeadingTwoProfession {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	color: #474747;
	/* margin: 22px 0%; */
}
.ProfessionLabelProfession {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 15px;
	line-height: 15px;
	text-align: center;
	color: #e20a0a;
	width: 100%;
	margin-top: 10px;
}

#GridDesktop {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	margin-left: 1%;
	margin-top: 20px;
}
.ProfessioncheckboxDesktop {
	padding: 15px 10px;
}

.ProfessioncheckboxDesktop input {
	position: absolute;
	left: -9999px;
}

.ProfessioncheckboxDesktop div label {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 65px;
	width: 150px;
	color: #989898;
	background-color: #fff;
	white-space: nowrap;
	cursor: pointer;
	user-select: none;
	transition: background-color 0.2s, box-shadow 0.2s;
	border: 1px solid #d9d9d9;
	border-radius: 5px;
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 10px;
	line-height: 10px;
	text-align: center;
}

.ProfessioncheckboxDesktop div label::before {
	content: "";
	display: block;
	position: absolute;
	transition: background-color 0.2s;
}

.ProfessioncheckboxDesktop input:checked + div label {
	background-color: #b3995b;
	color: white;
}

.ProfessioncheckboxDesktop input:checked + div label::before {
	background-color: #fff;
}

.Professionmargin {
	margin: 15px 0px;
	width: 100%;
}

.ProfessionInputSignUp {
	width: 100%;
	height: 60px;
	border: 1px solid #c1c1c1;
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: 600;
	font-style: normal;
	font-size: 17px;
	line-height: 17px;
	text-align: left;
	color: #474747;
	padding: 15px 26px;
}
.ProfessionInputSignUp:focus {
	border: 0px;
}
.ProfessionForgot {
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	text-align: right;
	color: #34529e;
	margin-bottom: 5px;
}
.ProfessionButtonDivProfession {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
	margin-top: 45px;
}
.ProfessionButtonProfession {
	width: 40%;
	height: 60px;
	background: #34529e;
	border-radius: 5px;
	color: #fff;
	font-family: "Josefin Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
}

.ProfessionSignup {
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	color: #818181;
	margin-bottom: 30px;
}
.ProfessionNewAccount {
	color: #34529e;
	font-weight: 700;
}
