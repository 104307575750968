.tabPanel {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	margin: 80px 0px;
}
.TabFull {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
}

.Tab {
	display: flex;
	flex-direction: row;
	width: 100%;
	overflow: hidden;
	font-family: "Josefin Sans", sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	color: #c8c8c8;
}

.Circle {
	background: #c8c8c8;
	padding: 10px;
	border-radius: 100%;
	margin-right: 37px;
}
.Line {
	border: 1px solid #c8c8c8;
	height: 80px;
	width: 0px;
	margin: 0px 9px;
}

.activeTab {
	color: #34529e;
}
.activeCircle {
	background-color: #34529e;
}
.activeLine {
	border: 1px solid #34529e;
}
