@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans");

.Desktop {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	overflow: hidden;
	background-color: #fff;

	.Margin {
		padding: 2% 50px;
		@media screen and (max-width: 500px) {
			padding: 2% 10px;
		}
	}
	/* Header */
	.Header {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: space-between;
		justify-content: space-between;
	}

	.Login {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 30px;
		/* identical to box height, or 200% */

		text-align: center;

		color: #585858;
		background-color: transparent;
		border: 0px;
	}
	.Join {
		background: #34529e;
		border-radius: 5px;
		width: 94px;
		height: 45px;
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 30px;
		text-align: center;
		color: #fff;
		margin: 0px 25px;
		border: 0px;
		@media screen and (max-width: 500px) {
			margin-right: 0%;
		}
	}
	/* Header */
	/* Section One */
	.SectionOne {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		overflow: hidden;
		padding: 5% 2%;
		@media screen and (max-width: 500px) {
			flex-direction: column-reverse;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}
	.HalfRow {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		margin: 5% 2%;
		margin-right: 7%;
		@media screen and (max-width: 500px) {
			width: 100%;
			align-items: center;
			align-content: center;
			justify-content: center;
			margin: 5% 0%;
			margin-right: 0%;
		}
	}
	.blueTitle {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		line-height: 61px;
		color: #34529e;
		text-align: left;
		width: 100%;
		@media screen and (max-width: 1100px) {
			font-size: 40px;
			line-height: 51px;
		}
		@media screen and (max-width: 800px) {
			font-size: 30px;
			line-height: 41px;
		}
		@media screen and (max-width: 500px) {
			text-align: center;
		}
	}
	.MusicField {
		width: 499px;
		background: #ffffff;
		border: 1px solid #b3b3b3;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		line-height: 18px;
		text-align: left;
		padding: 27px 25px;
		color: #666666;
		margin: 5% 0%;
		@media screen and (max-width: 1100px) {
			width: 400px;
		}
		@media screen and (max-width: 800px) {
			width: 300px;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}
	.Image {
		width: 520px;
		height: 520px;
		@media screen and (max-width: 1100px) {
			width: 400px;
			height: 400px;
		}
		@media screen and (max-width: 800px) {
			width: 300px;
			height: 300px;
		}
	}

	/* Section One End */

	/* Section Two */
	.SectionTwo {
		display: flex;
		flex-direction: column;
		background: #fdefe5;
		justify-content: center;
		width: 100%;
		overflow: hidden;
		padding: 5% 2%;
	}
	.WhipPlatHeading {
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 35px;
		color: #34529e;
	}

	.SectionTwoRow {
		display: flex;
		flex-direction: row;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin: 90px 0px;
		@media screen and (max-width: 800px) {
			margin: 50px 0px;
			flex-direction: column;
		}
	}

	.Card {
		width: 397px;
		height: 440px;
		box-shadow: 0px 10px 30px rgba(26, 94, 206, 0.25);
		border-radius: 10px;
		padding: 30px 20px;
		margin: 0px 25px;
		@media screen and (max-width: 800px) {
			margin: 25px 0px;
			width: 90%;
			height: 340px;
		}
		@media screen and (max-width: 500px) {
			height: 410px;
			margin: 15px 0px;
			width: 95%;
		}
	}
	.vectorImgBox {
		display: flex;
		flex-direction: row;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 120px;
		background: #ffffff;
		border-radius: 20px;
	}
	.CardVectorImage {
		width: 50px;
		height: 50px;
	}
	.titleCard {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 30px;
		/* or 100% */
		color: #ffffff;
		text-align: left;
		margin-top: 68px;
		margin-bottom: 52px;
	}

	.textCard {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 15px;
		/* identical to box height */

		text-align: left;

		color: #ffffff;
	}

	/* Section Two End */

	/* Section Three */
	.SectionThree {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		padding: 100px 110px;
		@media screen and (max-width: 800px) {
			padding: 50px 60px;
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			padding: 50px 30px;
			width: 100%;
		}
		@media screen and (max-width: 350px) {
			padding: 50px 0px;
			width: 100%;
		}
	}
	.SectionThreeInner {
		width: 50%;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		@media screen and (max-width: 800px) {
			width: 100%;
		}
	}
	.HeadingSectionThree {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 35px;
		line-height: 40px;
		/* or 114% */
		text-align: left;
		color: #b3995b;
		@media screen and (max-width: 800px) {
			text-align: center;
		}
	}
	.PostButton {
		width: 603px;
		height: 106px;
		background: #b3995b;
		border-radius: 5px;
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */
		border: 0px;
		text-align: center;
		margin: 80px 0px;
		color: #ffffff;
		@media screen and (max-width: 800px) {
			margin-bottom: 20px;
		}
		@media screen and (max-width: 800px) {
			width: 300px;
			height: 70px;
			margin: 50px 0pc;
			margin-bottom: 20px;
		}
	}

	/* Section Three End */

	/*  */
	/*  */
	/*  */

	/* Section Four */
	.Section4 {
		background: #f4fffd;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 100%;
	}
	.Section4Inner {
		padding: 100px 110px;
		width: 85%;
		@media screen and (max-width: 1100px) {
			padding: 50px 70px;
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			padding: 30px 0px;
			width: 100%;
		}
		@media screen and (max-width: 400px) {
			padding: 15px 0px;
			width: 100%;
		}
	}
	.Section4InnerRow {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		@media screen and (max-width: 800px) {
			padding: 50px 70px;
			width: 100%;
			flex-direction: column;
		}
		@media screen and (max-width: 400px) {
			padding: 0px 20px;
			width: 100%;
		}
	}
	.Box {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 50%;
		@media screen and (max-width: 800px) {
			width: 100%;
		}
	}
	.Box1Section4 {
		margin-right: 250px;
		@media screen and (max-width: 1100px) {
			margin-right: 100px;
		}
		@media screen and (max-width: 800px) {
			margin-right: 0px;
		}
	}
	.BoxHeading {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		line-height: 30px;
		/* or 76% */

		text-align: center;

		color: #303030;
		margin: 10% 0%;
	}
	.BoxText {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 19px;
		/* or 76% */

		text-align: center;

		color: #303030;
	}
	.ImageSectionFour {
		width: 350px;
		height: 325px;
	}
	.SectionFourButton {
		width: 918px;
		height: 130px;
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 19px;
		/* identical to box height, or 63% */
		text-align: center;
		color: #ffffff;
		background: #34529e;
		border-radius: 10px;
		margin: 65px 0px;
		margin-bottom: 0px;
		@media screen and (max-width: 800px) {
			width: 80%;
			height: 80px;
			font-size: 25px;
		}
	}

	/* Section Four End */

	/*  */
	/*  */
	/*  */
	/*  */

	/* Section 5 */
	.Section5 {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 100%;
		padding: 150px 110px;
		@media screen and (max-width: 1100px) {
			padding: 0px 50px;
			width: 100%;
		}
		@media screen and (max-width: 800px) {
			padding: 50px 20px;
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			padding: 50px 20px;
			width: 100%;
			flex-direction: column;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	.ImageSection5 {
		width: 650px;
		height: 650px;
		margin-right: 5%;
		@media screen and (max-width: 1100px) {
			width: 450px;
			height: 650px;
			margin-right: 5%;
		}
		@media screen and (max-width: 800px) {
			width: 350px;
			height: 450px;
			margin-right: 5%;
		}
	}

	.RightSection5 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
	}
	.RightHeading {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 50px;
		/* identical to box height, or 125% */
		text-align: left;

		color: #34529e;
		@media screen and (max-width: 1100px) {
			font-size: 30px;
			line-height: 40px;
		}
		@media screen and (max-width: 800px) {
			font-size: 25px;
			line-height: 30px;
		}
	}
	.RightText {
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		line-height: 35px;
		text-align: left;
		color: #34529e;
		@media screen and (max-width: 1100px) {
			font-size: 17px;
			line-height: 20px;
		}
	}
	.SecFiveTabPanel {
		margin: 70px 0px;
		width: 100%;
		@media screen and (max-width: 1100px) {
			margin: 30px 0px;
		}

		@media screen and (max-width: 800px) {
			margin: 10px 0px;
		}
	}
	.SecFiveTab {
		height: 80px;
		font-family: "Josefin Sans", sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 50px;
		/* identical to box height, or 250% */
		color: #b3995b;
		background: #fff3d6;
		border-radius: 5px;
		margin: 20px 0px;
		padding: 15px 60px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: space-between;
		justify-content: space-between;
		@media screen and (max-width: 1100px) {
			height: 60px;
			margin: 20px 0px;
			width: 100%;
		}
		@media screen and (max-width: 800px) {
			height: 50px;
			padding: 5px 10px;
			width: 100%;
			font-size: 17px;
			line-height: 20px;
		}
	}

	/* Section Five End */

	/*  */
	/*  */
	/*  */
	/*  */

	/* Section 6 */
	.Section6 {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 100%;
		padding: 150px 110px;
		@media screen and (max-width: 1100px) {
			width: 100%;
			padding: 70px 50px;
			padding-top: 30px;
		}
		@media screen and (max-width: 800px) {
			width: 100%;
			padding: 70px 50px;
			padding-top: 30px;
			flex-direction: column-reverse;
		}
	}

	.ImageSection6 {
		width: 50%;
		height: 580px;
		@media screen and (max-width: 800px) {
			width: 100%;
			height: 100%;
		}
	}

	.LeftSection6 {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		/* justify-content: center; */
		background: #fef6e3;
		height: 580px;
		padding: 52px 100px;
		width: 50%;
		@media screen and (max-width: 1100px) {
			padding: 20px 10px;
		}
		@media screen and (max-width: 800px) {
			padding: 20px 10px;
			width: 100%;
			height: 100%;
		}
	}
	.LeftHeading {
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		line-height: 50px;
		/* or 100% */
		color: #071c57;
		margin-bottom: 220px;
		@media screen and (max-width: 1100px) {
			width: 100%;
			font-size: 40px;
			margin-bottom: 220px;
		}
		@media screen and (max-width: 800px) {
			width: 100%;
			font-size: 30px;
			margin-bottom: 20px;
		}
	}
	.LeftButton {
		width: 398px;
		height: 100px;
		background: #34529e;
		border-radius: 50px;
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 50px;
		/* identical to box height, or 167% */
		color: #ffffff;
		@media screen and (max-width: 500px) {
			width: 80%;
			height: 50px;
			font-size: 25px;
			line-height: 30px;
		}
	}
	/* Section 6 End */

	/*  */
	/*  */
	/*  */
	/*  */

	/* Section 7 */

	.Section7 {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 100%;
		padding: 50px 110px;
		background: #34529e;
		@media screen and (max-width: 1100px) {
			padding: 30px 60px;
		}
		@media screen and (max-width: 500px) {
			padding: 30px 60px;
			flex-direction: column;
		}
		@media screen and (max-width: 400px) {
			padding: 30px 20px;
			flex-direction: column;
		}
	}

	.ImageSection7 {
		width: 475px;
		height: 400px;
		margin-right: 5%;
		@media screen and (max-width: 1100px) {
			width: 400px;
			height: 325px;
		}
		@media screen and (max-width: 800px) {
			width: 350px;
			height: 275px;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
			height: 275px;
		}
	}

	.Section7Right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
		justify-content: flex-start;
		width: 50%;
		@media screen and (max-width: 500px) {
			width: 100%;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	.TextRight {
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 40px;
		line-height: 50px;
		color: #ffffff;
		text-align: left;
		@media screen and (max-width: 1100px) {
			font-size: 30px;
			line-height: 40px;
		}
		@media screen and (max-width: 800px) {
			font-size: 25px;
			line-height: 35px;
		}

		@media screen and (max-width: 500px) {
			text-align: center;
		}
	}
	.InstallButton {
		width: 80%;
		height: 80px;
		background: #ffffff;
		border-radius: 5px;
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 50px;
		margin: 50px 0px;
		/* identical to box height, or 250% */

		color: #34529e;
		@media screen and (max-width: 800px) {
			height: 50px;
			width: 70%;
		}
		@media screen and (max-width: 500px) {
			height: 50px;
			width: 90%;
		}
	}

	/* Section 7 End */

	/*  */
	/*  */
	/*  */
	/*  */

	/* Footer */
	.footerSection {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-content: space-between;
		justify-content: space-between;
		align-items: center;
		padding: 100px 50px;
		padding-bottom: 25px;
		@media screen and (max-width: 1100px) {
			padding: 100px 20px;
			padding-bottom: 20px;
		}
		@media screen and (max-width: 800px) {
			flex-direction: column;
			padding: 100px 40px;
			padding-bottom: 20px;
		}
		@media screen and (max-width: 500px) {
			flex-direction: column;
			padding: 50px 10px;
			padding-bottom: 20px;
		}
	}

	.TabletInVisible {
		display: flex !important;
		@media screen and (max-width: 800px) {
			display: none !important;
		}
		@media screen and (max-width: 500px) {
			display: flex !important;
			margin: 10px 0px;
		}
	}
	.TabletVisible {
		display: none;
		@media screen and (max-width: 800px) {
			display: flex;
			flex-direction: row;
			align-items: center;
			align-content: space-between;
			justify-content: space-between;
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			display: none;
		}
	}
	.MobilLinkWrapper {
		@media screen and (max-width: 500px) {
			display: flex;
			flex-direction: column;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 100%;
		}
	}
	.Link {
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		/* identical to box height, or 150% */

		text-align: center;
		margin: 0px 27px;

		color: #34529e;
		@media screen and (max-width: 1100px) {
			font-size: 17px;
			line-height: 20px;
			margin: 0px 10px;
			width: 100%;
		}
		@media screen and (max-width: 500px) {
			font-size: 17px;
			line-height: 20px;
			margin: 5px 10px;
			width: 100%;
			overflow: hidden;
			padding-right: 0%;
		}
	}

	.CopyRight {
		font-family: Josefin Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		display: flex;
		flex-direction: row;
		align-content: space-between;
		justify-content: space-between;
		align-items: center;
		/* identical to box height, or 150% */

		text-align: center;

		color: #aaaaaa;
		@media screen and (max-width: 1100px) {
			font-size: 17px;
			line-height: 20px;
			width: 20%;
			align-content: center;
			justify-content: center;
			align-items: center;
		}
		@media screen and (max-width: 800px) {
			width: 50%;
			text-align: right;
			align-content: flex-end;
			justify-content: flex-end;
			align-items: flex-end;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
			text-align: center;
			align-content: center;
			justify-content: center;
			align-items: center;
			margin: 15px 0px;
		}
	}
}
