::placeholder{
	color: lightgrey;
	font-weight: 600;
	text-transform: capitalize;
}

.FieldsOfRegister {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
}
#registerForm button {
	display: block;
	margin: 0 auto;
	width: 90vw;
	color: #34529e;
	border: 1px solid white;
	background-color: white;
	margin-top: 5vh;
	height: 40px;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	font-weight: 700;
}
#register-welcometext {
	font-family: Montserrat;
	text-align: left;
	padding-left: 8vw;
	color: white;
	font-size: 15px;
}
#register-welcometext p {
	font-weight: 400;
	width: 100%;
	text-align: center;
}
#register-welcometext p span {
	font-weight: 600;
	margin-left: 0;
}
#registerForm {
	overflow-y: scroll;
	overflow-x: hidden;
	flex-wrap: wrap;
	font-family: Montserrat;
	flex-wrap: wrap;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
}
#registerForm p {
	text-align: left;
	position: relative;
	color: white;
	/* font-family: Montserrat; */
	font-weight: 600;
	font-size: 12px;
	margin-top: 2vh;
	margin-bottom: 7px;
	width: 100%;
}
#registerForm input {
	width: 90vw;
	height: 40px;
	/* font-family: Montserrat; */
	font-weight: 500;
	font-size: 10px;
	padding: 10px;
	border: 1px solid #ffffff;
	border-radius: 2px;
	background-color: transparent;
	color: white;
}
#registerForm input:focus {
	outline: none;
}
#registerForm select {
	width: 90vw;
	height: 40px;
	font-weight: 500;
	font-size: 10px;
	padding: 6px;
	border: 1px solid white;
	border-radius: 2px;
	background-color: transparent;
	color: white;

}
#registerForm select option{
	color:black;
	font-size:10px;
	position: relative;
}
#registerForm select:focus {
	outline: none;
}
#register-create-wrapper {
	display: flex;
	justify-content: center;
	align-items: baseline;
	position: relative;
	margin-top: 5%;
}
#register-create-wrapper p {
	color: white;
	font-weight: 500;
	font-size: 12px;
}
#register-create-wrapper a {
	color: white;
	font-weight: 600;
	font-size: 14px;
	margin-left: 8px;
}
#login-btn-wrapper button {
	margin: 0 auto;
	width: 90vw;
	color: #34529e;
	border: 1px solid white;
	background-color: white;
	margin-top: 3vh;
	height: 40px;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
}
#login-btn-wrapper button:focus {
	outline: none;
}
#login-btn-wrapper a {
	color: white;
	font-weight: 500;
	font-size: 12px;
}
#login-btn-wrapper a p {
	margin-top: 12px;
}
.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.paper {
	background-color: white;
	border: 2px solid orange;
	width: 90vw;
	height: 60vh;
}
.paper h2 {
	font-family: Montserrat;
	font-weight: 400;
	font-size: 15px;
	text-align: center;
	margin-top: 11px;
}
.paper p {
	font-family: Montserrat;
	font-weight: 400;
	font-size: 15px;
	text-align: center;
	margin-top: 20px;
}
.paper img {
	width: 50%;
	margin: 0 auto;
	padding-top: 47px;
}
.paper a {
	background-color: #34529e;
	border-radius: 5px;
	width: 60%;
	display: flex;
	align-self: center;
	margin: 0 auto;
	justify-content: center;
	color: white;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-top: 13px;
}
