#helpPageContainer {
    position: relative;
    top: 60px;
    padding: 13px;
}
#helpPageHeading {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    color: #666666;
    text-align: left;
}
#helpPagePara {
    margin-top: 45px;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 400;
    color: #4f4f4f;
    text-align: left;
}
#helpFirstSectionContainer {
    top: 45px;
    position: relative;
    padding: 2.5vw;
}
#helpFirstSection {
    width: 95vw;
    text-align: center;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    height: 217px;
}
#helpFirstSection p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 10px;
    color: #000;
}
#helpFirstSection img {
    margin: 0 auto;
}
#helpEmailImg {
    padding-top: 14px;
    padding-bottom: 7px;
}
#helpSmartphoneImg {
    padding-top: 34px;
    padding-bottom: 15px;
}
#helpSecondSectionContainer {
    position: relative;
    top: 50px;
    padding: 2.5vw;
}
#helpSecondSectionContainer p {
    text-align: left;
    font-family:Montserrat;
    font-weight: 400;
    font-size: 12px;
    color: #4f4f4f;
}
#helpSecondSection {
    width: 95vw;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.25);
    height: 120px;
    margin-top: 13px;
}
#helpSocialImgs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    height: 100%;
}