#sharePhotoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
#shareBackButton {
  position: absolute;
  width: 20px;
  left: 13.44px;
  top: 12.33px;
  z-index: 1;
}
#shareCaptureButton {
  position: relative;
  bottom: 100px;
  margin: 0 auto;
}
#sharePhotoCapture {
  position: relative;
  top: -64px;
  left: 0;
}
#sharePhotoUploadingBtns {
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 100px;
  justify-content: space-evenly;
  width: 100vw;
}
.sharePhotoFlip {
  position: absolute;
  right: 20px;
  top: 35px;
  z-index: 1;
}
.sharePhotoText {
  position: absolute;
  top: 66px;
  z-index: 1;
  right: 24.5px;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}
#shareModalWrapper {
  position: absolute;
  bottom: 50vh;
  left: 0.8vw;
}
#shareModal {
  width: 98vw;
  height: 80px;
  padding: 19px 0 0px 15px;
  color: black;
  background-color: white;
  text-align: left;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}
#shareModalBtnsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}
#shareModalBtns button {
  font-weight: 600;
}
#shareModalBtns {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  align-items: flex-end;
  height: 50%;
}
#shareCaptionImagePass {
  display: none;
}
#sharePhotoBottomLine {
  border-bottom: 1px solid #c6c6c6;
  height: 50px;
  width: 92%;
  margin: 0 auto;
}
#uploadingProgress {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.changeDurationBtn {
  /* color: white; */
  font-weight: bold;
  margin-left: 10px;
  color: white;
}
.durationTime {
  height: 20px;
  position: relative;
  bottom: 92px;
  display: flex;
  color: black;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.recordingIcon {
  height: 20px;
  position: relative;
  bottom: 92px;
  display: flex;
  color: black;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.secondText {
  position: absolute;
  transition: 0.3s;
  /* left: 0; */
}
.changeDurationBtnInactive.sixty {
  left: 34%;
}
.changeDurationBtnInactive.thrity {
  right: 34%;
}
/* .changeDurationBtn::before {
    content: '.';
    display: inline-block;

    position: absolute;
    bottom: -0.3em;
    left: 15px;

    text-align: center;
    width: 100%;
} */
.changeDurationBtnInactive {
  margin-left: 10px;
  color: white;
  font-size: 11px;
  /* position: absolute;
    bottom: 70px; */
}
.activatePremiumModal {
  width: 218px;
  height: 169px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;
  border-radius: 3px;
  position: absolute;
  color: white;
}
.activatePremiumModal p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #000000;
  margin-bottom: 33px;
}
.activatePremiumModal .premiumModalButton {
  background: #34529e;
  border-radius: 2px;
  width: 139px;
  height: 37px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #ffffff;
}
.activatePremiumModal .innerActivatePremiumModal {
  position: relative;
  height: 100%;
  padding: 36px 22px 27px;
}
.activatePremiumModal .closeButton {
  position: absolute;
  right: 5px;
  top: 5px;
}
#sharePhotoCamera {
  width: 100vw;
  height: 100vh;
}
#sharePhotoCamera #innerSharePhotoCamera {
  width: 100%;
  height: 100%;
}
/* #sharePhotoCamera #innerSharePhotoCamera */
