.post-wrapper {
  padding-top: 10px;
  padding-bottom: calc(100vh - 600px);
  background-color: #f6f8fb;
}
.root {
  max-width: 100vw;
}
.media {
  height: 0;
  padding-top: "56.25%";
}
.expand {
  transform: "rotate(0deg)";
  margin-left: "auto";
  /* transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }) */
}
.expandOpen {
  transform: "rotate(180deg)";
}
.avatar {
  background-color: #f3f3f3;
}
.MuiCard-root {
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.MuiCardHeader-title {
  text-align: left;
  font-size: 11px !important;
  font-family: Montserrat !important;
  font-weight: 600 !important;
}
.MuiCardHeader-subheader {
  text-align: left;
  font-size: 8px !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
#textPostContent {
  text-align: left;
  font-family: Montserrat;
  font-weight: 500;
  padding: 0 16px 0 16px;
}
#cardContent {
  padding: 16px 0 16px 0;
}
.MuiTypography-body1 {
  font-size: 10px !important;
}
#reaction-wrapper {
  display: flex;
  justify-content: left;
  align-items: baseline;
  width: 100%;
  margin: 10px 0px;
}
#post-share-btn {
  position: relative;
  top: 1.2px;
  outline: none;
}
#post-like-btn {
  position: relative;
  top: 1px;
  outline: none;
}
#reactions {
  /* width: 30px; */
  /* height: 40px; */
}
#loading-wrapper {
  padding-top: 50px;
  overflow-y: scroll;
}
.loading-wrap {
  display: flex;
  justify-content: center;
  height: 40vh;
}
#loading-img {
  margin: 0 0% 16px 5%;
  width: 30%;
  height: 15px;
  border-radius: 20px;
}
#loading-txt {
  width: 50%;
  margin: 0 0 16px 5%;
  height: 15px;
  border-radius: 20px;
}
#loading-body {
  width: 40%;
  height: 15px;
  margin: 0 0 16px 5%;
  border-radius: 20px;
}
#loading-internal {
  padding: 20px;
}
#postProfession {
  display: flex;
}
#caption {
  text-align: center;
  font-family: Montserrat;
  font-weight: 500;
  padding: 0 16px 0 16px;
  font-size: 13px !important;
  margin-top: 10px;
}
#ImagePostContent {
  width: 100%;
}
#likeWrap {
  width: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#likeWrap button img {
  margin: 5px auto;
}
#likeWrap p {
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
}
#commentWrap {
  width: 74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#commentWrap button img {
  margin: 5px auto;
  margin-bottom: 6px;
  width: 16px;
  height: 16px;
}
.LSCButtons {
  width: 1.125rem;
  height: auto;
}
.LSCButtons:focus {
  outline: none;
}
#commentWrap p {
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
}
#reactionForm {
  display: flex;
  width: 60%;
  /* margin-left: -12%; */
  /* justify-content: space-evenly; */
  align-items: baseline;
}
#shareWrap {
  width: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#shareWrap button img {
  margin: 0px auto;
  margin-bottom: 8px;
  position: relative;
  top: 3px;
}
#shareWrap p {
  font-family: Montserrat;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
}

/* Loader 2 */
.loaderDivPost {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}
.loader-2 {
  display: block;
  height: 32px;
  width: 32px;
  -webkit-animation: loader-2-1 3s linear infinite;
  animation: loader-2-1 3s linear infinite;
}
@-webkit-keyframes loader-2-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(16px, 32px, 32px, 0);
  -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid #99aad2;
  border-top: 3px solid #2d51a3;
  border-radius: 50%;
  -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
@-webkit-keyframes loader-2-3 {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes loader-2-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-2 span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.dropdown {
  position: relative;
  top: 100%;
  left: 0;
  width: 300px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
