* {
  font-family: Montserrat;
}
input[type="text"],
input[type="number"] {
  border: solid 1px #4f4f4f;
  height: 40px;
  width: 100%;
  left: 190px;
  top: 312px;
  border-radius: 4px;
  padding: 2% 2%;
  background-color: transparent;
}

/* input[type="number"] {
    border: solid 1px #4F4F4F;
    height: 40px;
    width: 158px;
    left: 190px;
    top: 312px;
    border-radius: 4px;
    margin: 6% 1%;
    background-color: transparent;
}

input[type="date"] {
    border: solid 1px #4F4F4F;
    height: 40px;
    width: 158px;
    left: 190px;
    top: 312px;
    border-radius: 4px;
    margin: 6% 1%;
    background-color: transparent;
} */

select {
  border: solid 1px #4f4f4f;
  height: 40px;
  width: 158px;
  left: 190px;
  top: 312px;
  border-radius: 4px;
  margin: 6% 1%;
  background-color: transparent;
}
option {
  font-size: smaller;
}

input[type="select"] {
  border: solid 1px #4f4f4f;
  height: 40px;
  width: 158px;
  left: 190px;
  top: 312px;
  border-radius: 4px;
  margin: 6% 1%;
}

textarea {
  border: solid 1px #4f4f4f;
  padding: 2% 0;
  border-radius: 4px;
  margin: 2% 0;
  background-color: transparent;
}

.gig-btn {
  width: 100%;
  background: #0000;
  padding: 2% 0;
}

.postgig-btn-wrapper {
  width: 100%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
}

.add-info-wrapper {
  margin: 5% 0;
}
.gig-banner-preview {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  margin: 1rem 0;
}
.topPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.uploadButton {
  background-color: aquamarine;
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.center-loader {
  display: flex;
}
