#menuIconDiv {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    position: absolute;
    right: 0px;
    top: 92px;
}
#menuIconContainer {
    outline: none;
}
.menuVert {
    position: fixed;
    bottom: 0px;
    z-index: 10;
    background-color: #34529e;
    color: white;
    display: grid;
    width: 100%;
    padding-bottom: 69px;
    padding-top: 20px;
    border-radius: 5px 5px 0 0;
}
.menuVert div {
    text-align: left;
    margin-left: 22px;
}
.menuVert div button {
    padding-bottom: 10px;
    padding-top: 10px;
    outline: none;
    text-align: left;
    padding-left: 11px;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    width: 70%;
}
#createIcon {
    height: 15px;
    width: 15px;
}
#simple-menu {
    position: fixed;
}
#recommendationSubmitContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
}
.Availability {
    border: 1px solid #b3995b;
    box-sizing: border-box;
    border-radius: 3px;
    width: 65%;
    margin: 5px auto;
    padding: 2.5% 15% 2.5% 15%;
}
.otherPersonMessage {
    border: 1px solid #34529e;
    box-sizing: border-box;
    width: 65%;
    margin: 5px auto;
    padding: 14px 0px;
    background: #34529e;
    border-radius: 3px;
    color: white;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;

    color: #ffffff;
}
.otherPersonMessage.disable{
    opacity: 0.5;
}

.Availability p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    /* identical to box height */

    text-align: center;
    color: #b3995b;
}
