
::placeholder {
    color: lightgrey;
    font-weight: 600;
    text-transform: capitalize;
}

#login-wrapper {
    width: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    background-image: url("../../../assets/images/login/Loginbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 100%; */
    min-height: 100vh;
    font-family: "Montserrat";
    flex-wrap: wrap;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

#overlayLogin {
    min-height: 100vh;
    font-family: "Montserrat";
    flex-wrap: wrap;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: rgba(52, 82, 158, 0.9);
}

#login-welcometext {
    font-family: "Montserrat";
    text-align: left;
    padding-left: 8vw;
    color: white;
    font-size: 15px;
    margin-top: 5vh;
    width: 100%;
}

#login-welcometext p {
    font-weight: 400;
    width: 100%;
    text-align: left;
}

#login-welcometext p span {
    font-weight: 600;
    margin-left: 0;
}

#login-input {
    /* margin-top: 10vh; */
    overflow-y: scroll;
    overflow-x: hidden;
    flex-wrap: wrap;
    height: 100%;
    font-family: Montserrat;
    flex-wrap: wrap;
    display: flex;
    -webkit-flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    font-weight: 700;
}

#login-input p {
    text-align: left;
    left: 5vw;
    position: relative;
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-top: 4vh;
    margin-bottom: 7px;
    width: 100%;
}

#login-email {
    width: 90vw;
    height: 40px;
    /* font-family: "Montserrat"; */
    font-weight: 300;
    font-size: 10px;
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 2px;
    background-color: transparent;
    color: white;
}

#login-password {
    width: 90vw;
    height: 40px;
    /* font-family: "Montserrat"; */
    font-weight: 300;
    font-size: 10px;
    padding: 10px;
    border: 1px solid #ffffff;
    border-radius: 2px;
    background-color: transparent;
    color: white;
}

#login-email:focus {
    outline: none;
}

#login-btn-wrapper button {
    margin: 0 auto;
    width: 90vw;
    color: #34529e;
    border: 1px solid white;
    background-color: white;
    margin-top: 5vh;
    height: 40px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

#login-btn-wrapper button:focus {
    outline: none;
}

#login-btn-wrapper a {
    color: white;
    font-weight: 500;
    font-size: 12px;
}

#login-btn-wrapper a p {
    margin-top: 12px;
}

#login-create-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-top: 10%;
}

#login-create-wrapper p {
    color: white;
    font-weight: 500;
    font-size: 12px;
}

#login-create-wrapper a button {
    background: #b3995b;
    border-radius: 3px;
    height: 40px;
    width: 93px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    margin-left: 8px;
}
