#feedbackHeading {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 600;
	color: #666666;
	text-align: left;
}
#feedbackPara {
	font-family: Montserrat;
	font-size: 10px;
	font-weight: 400;
	color: #000000;
	text-align: left;
	width: 85vw;
	margin-top: 23px;
}
#feedbackHeadContainer {
	position: relative;
	padding: 13px;
}
#feedbackInputContainer {
	position: relative;
	padding: 13px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	align-content: flex-end;
	justify-content: flex-end;
	width: 100%;
}
#feedbackInputContainer textarea {
	border: 1px solid #9f9f9f;
	font-weight: 400;
	font-family: Montserrat;
	font-size: 9px;
	text-align: left;
	width: 93vw;
	height: 148px;
	padding: 13px;
}
#feedbackInputContainer textarea:focus {
	outline: none;
}
#feedbackSubmitButton {
	background: #34529e;
	border-radius: 3px;
	font-size: 12px;
	font-family: Montserrat;
	font-weight: 700;
	padding: 12px 56px 12px 56px;
	color: white;
	margin: 5% 0%;
}
