.ratingHeadings {
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}
#ratingSubmitButton{
    background: #34529E;
    border-radius: 3px; 
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center; 
    color: white;
    padding: 14px 31px;
    position: absolute;
    bottom: 20vh;
    right: 14px;
}
#ratingContainer {
    height: 53vh;
    margin-left: 14px;
}
.star-ratings {
    text-align: left !important;
    display: block !important;
    margin-bottom: 9px;
}
#ratingHead{
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
}