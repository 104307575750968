.MainAllUser {
	position: relative;
	height: calc(100vh - 120px);
	display: flex;
	overflow-y: scroll;
	flex-flow: row nowrap;
	margin: 0% 2%;
}
.AllUserCard {
	width: 80%;
	margin: 0% 5%;
	margin-left: 0%;
	scroll-behavior: auto;
	scroll-snap-align: center;
	scroll-snap-type: both mandatory;
}
.browse-img {
	width: min-content;
	/* margin: 0 auto; */
	height: 50vh;
}
#browseImgWrap {
	margin-top: 10vh;
	width: 85vw;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.browseName {
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: center;
	margin-top: 10px;
}
.browseSubName {
	font-family: Montserrat;
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	letter-spacing: 0em;
	text-align: center;
	color: #666666;
}
.browseCountry {
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
	color: #b3995b;
}
.browseBack {
	filter: invert(100);
}
#browsebackNav {
	display: flex;
	align-items: center;
	width: 95%;
	margin: 19px auto 0 auto;
}
#browsebackNav p {
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
	margin-left: 10px;
}
#allUserBtn {
	margin-top: 20px;
}
#profession {
	background: transparent;
	width: 98vw;
	border: 1px solid black;
	padding: 10px;
}
#browseSearchHead {
	font-family: Montserrat;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	line-height: 10px;
	letter-spacing: 0em;
	text-align: left;
	margin: 26px 0px 6px 5px;
}
