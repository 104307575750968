@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.App {
  text-align: center;
    /*color:#000000 !important;*/
    /*font-size: 10px;*/
    /*font-family: 'Montserrat', sans-serif;*/
}
a {
  text-decoration: none;
}
.text-body a {
  color: blue;
  text-decoration: underline;
}
.share-text {
  font-size: 10px;
}
.emoji-icon {
  position: relative;
  top: 150px;
  left: -20px;
}
.flex .promoted {
  margin-left: auto;
}
