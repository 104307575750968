@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans");
.logo-section {
  background-color: #34529e;
  width: 100%;
 
}

.connect-section {
  font-family: "Josefin Sans", sans-serif;
  color: #34529e;
  font-style: normal;
  
}
.contact-text {
  font-size: 3rem;
   font-family: "Josefin Sans", sans-serif;
}
.whip-logo {
  width: 20rem;
}
