body {
	background-color: #f6f8fb !important;
}
.chatSearchField {
	outline: none;
	padding: 0px 5px;
	color: #ffff;
	margin: 0px 10px;
}
.chatSearchField:focus {
	border: 1px solid #fff;
}
.TopNav {
	height: 50px;
	width: 100vw;
}
.navbar {
	background-color: #ffffff;
	min-height: 50px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	/* -webkit-box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.25); */
	/* -moz-box-shadow:    0px 2px 4px 0px rgba(50, 50, 50, 0.25); */
	box-shadow:  0px 2px 4px 0px rgba(50, 50, 50, 0.25);
	z-index: 2;
	position: sticky;
	top: 0;
	width: 100vw;
	padding: 13px 0px;
	position: fixed;
}
.chatNav {
	background-color: #34529e;
	padding: 0px 10px;
	width: 100%;
}
.innerChatNav {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-around;
}
.chatNav .chatSearch {
	display: flex;
	align-items: center;
	margin-left: 13px;
}
.chatNav .chatSearch input {
	background-color: #34529e;
	/* border: none; */
}
.chatNav .addPhotoIcon {
	float: right;
}
.toplogo {
	width: 59.77px;
	height: 35px;
}
.menu-bars {
	margin-left: 1rem;
	font-size: 2rem;
	width: 100%;
	background: none;
	align-items: center;
	justify-content: space-between;
	display: flex;
}
.connect-menu-bars {
	margin-left: 1rem;
	font-size: 2rem;
	width: 10%;
	background: none;
	align-items: center;
	justify-content: space-between;
	display: flex;
}
.menu-bars div {
	width: 10%;
}
.chevronleft {
	position: absolute;
	left: 215px;
	top: 50px;
	font-size: 25px;
	width: 30px;
	height: 30px;
	color: #34529e;
}
.chevronleft:hover {
	background-color: rgba(232, 236, 241, 1);
	border-radius: 25px;
}
.nav-menu {
	background-color: #34529e;
	width: 250px;
	height: 100vh;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	left: -100%;
	transition: 850ms;
	z-index: 3;
}
.nav-menu.active {
	left: 0;
	transition: 350ms;
}
.nav-text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 0px 8px 16px;
	list-style: none;
	height: 60px;
}
.nav-text a {
	text-decoration: none;
	color: #f5f5f5;
	font-size: 12px;
	width: 95%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 16px;
	border-radius: 4px;
	font-family: Montserrat;
	font-weight: bold;
}
/* .nav-text a:hover {
    background-color: #1a83ff;
} */

.nav-menu-items {
	width: 100%;
}
.navbar-toggle {
	background-color: white;
	width: 100%;
	height: 134px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
#navSpan {
	margin-left: 16px;
}
.side-logo {
	margin: 0 auto;
}
.logoutbtn {
	position: absolute;
	bottom: 28px;
	left: 5px;
}
.aboutbtn a img {
	width: 22px;
	height: 22px;
}
