#editTopBar {
    background-color: #34529E;
    display: flex;
    align-items:center;
    height: 50px;
    padding-left: 13px;
    width: 100vw;
    font-family: Montserrat;
}
#editTopBar img {

}
#editProfileAppBar {
    box-shadow: none;
}
#editTopBar h1 {
    font-weight: 700;
    font-size: 13px;
    color: #ffffff;
    margin-left: 17.33px;
}
#editProfileAppBarTab {
    color:#34529E;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
}
#editProfileAppBarTab:focus {
    outline: none;
}
#editProfileAppBarTab span{margin: 0 !important;}
#editProfileForm {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
#editProfileForm p {
    margin-bottom: 8px;
}
#editProfileForm input {
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 5vh
}
#editProfileForm select {
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 10px;
    background-color: white;
    height: 40px;
    margin-bottom: 5vh
}
#edit-btn-wrapper button{
    background: #34529E;
    box-shadow: 0px 4px 20px rgba(52, 82, 158, 0.25);
    border-radius: 2px;
    width: 100%;
    color: white;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;

    padding: 14px;
}
.userImgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.userImg {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    margin: auto;
}
.profileOverlay {
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.camera {
    z-index: 1;
    width: 1.25rem;
    height: 1.25rem;
    margin: auto;
}
.profileImgUpload::-webkit-file-upload-button {
    visibility: hidden;
  }
.profileImgUpload {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background-color: transparent;
    border-radius: 100%;
    z-index: 2;
}