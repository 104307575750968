#npsSvg {
    height: 100vh;
    position: absolute;
}
#npsBtns {
    display: flex;
    justify-content: center;  
    border: 1px solid red; 
    align-items: center;
    margin: 0 auto;
    vertical-align: center;
    transform: translate3d(10,10,10);
}
#npsWidget {
    /* position: absolute; */
    border: 1px solid red;
}