.PromoteText {
	font-family: "Montserrat";
	margin-bottom: 10px;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */
	text-align: center;
	color: #303030;
}
.PromoteDialog {
	width: 222px;
	height: 136px;

	background: #ffffff;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 13px;
	text-align: center;

	color: #000000;
}
.PayButton {
	width: 98px !important;
	height: 40px !important;

	background: #34529e !important;
	border-radius: 5px !important;
	font-family: "Montserrat" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 11px !important;
	line-height: 13px !important;
	text-align: center !important;

	color: #fff !important;
	margin-top: 25px !important;
}
.closePromote {
	position: absolute;
	left: 92%;
	width: 15px !important;
	height: 15px !important;
	top: 2%;
}
.PromoteButton {
	width: 90vw;

	background: #34529e !important;
	box-shadow: 0px 4px 20px rgba(52, 82, 158, 0.25) !important;
	border-radius: 3px !important;
	height: 40px;
	font-family: "Montserrat" !important;
	font-style: normal;
	font-weight: bold !important;
	font-size: 10px !important;
	line-height: 12px !important;
	text-align: center !important;

	color: #ffffff !important;
	bottom: 85px;
	outline: none !important;
	position: absolute !important;
	left: 25px;
	margin: 0px !important;
	padding: 0px !important;
}
.Promote {
	z-index: 1;
	position: fixed !important;
	bottom: 75px;
	right: 15px;
	background: #34529e !important;
	box-shadow: 0px 4px 30px rgba(52, 82, 158, 0.25) !important;
	width: 60px;
	height: 60px;
	border-radius: 100% !important;
	font-family: "Montserrat" !important;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 9px !important;
	line-height: 11px !important;
	/* identical to box height */

	text-align: center !important;

	color: #ffffff !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	align-content: center !important;
	justify-content: center !important;
	outline: none !important;
}
.Promote svg {
	width: 20px;
	height: 20px;
	margin-bottom: 2px;
}
.ProfileLoaderWrapper {
	width: 100%;
	height: 30vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ProfileLoaderWrapper h1 {
	font-size: 20px;
}
.bgProfile {
	width: 100vw;
	height: 92px;
	position: absolute;
	top: 0px;
}
.profileTop {
	display: flex;
	justify-content: center;
	align-items: center;
}
.userPic {
	width: 98px !important;
	height: 98px !important;
	position: relative;
	margin-bottom: 2em;
	top: 1.7em;
}
.ConnectionsOfProfile {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	color: #34529e;
}
.BitLy {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	color: #7b7979;
}
#profileInfo h1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */

	text-align: center;

	color: #000000;
}
#profileInfo {
	font-size: 12px;
	font-weight: 500;
}
#connectPic {
	width: 15px;
	height: 13.75px;
}
#connectsNumber {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 10px;
	color: #34529e;
}
#connectsNumber img {
	margin-right: 10px;
}
#profileRating {
	border: 1px solid #cca20f;
	border-radius: 5px;
	position: absolute;
	left: 12px;
	top: 103px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
}
#profileRating p {
	font-weight: 700;
	font-size: 10px;
	position: relative;
	top: 1px;
	left: 1px;
}
#profileLocation {
	display: flex;
	align-items: center;
	font-weight: 400 !important;
	font-size: 10px !important;
	color: #616161;
}
#profileLocation img {
	margin-right: 10px;
}
.locationAndConnects {
	display: flex;
	justify-content: space-around;
	color: #616161;
	padding-top: 12px;
	width: 50%;
	margin: 0 auto;
}
.ConName {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	text-align: center;

	color: #616161;
	/* margin-left: 8px; */
}
#profileProfessionSection {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-family: "Montserrat";
	font-size: 12px;
	color: #313131;
	margin-top: 6px;
}
#profileGenreSection {
	display: flex;
	justify-content: center;
	color: #4f4f4f;
	margin-top: 12px;
}
#profileGenreHeading {
	font-family: "Montserrat";
	/* font-style: normal; */
	font-weight: 700 !important;
	font-size: 11px !important;
	line-height: 13px !important;
	text-align: center;
	margin-right: 5px;

	color: #4f4f4f;
}
#profileGenres {
	font-weight: 400;
	font-family: "Montserrat-Regular";
	font-size: 11px;
}
#profileAppBar {
	box-shadow: none;
	/* overflow-x: scroll; */
	margin-top: 30px;
	background-color: #f6f8fb;
}
#profileAppBar header {
	background-color: #fff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
#profileAppBar .css-19kzrtu {
	padding: 18px 12px;
}
#profileScrollBar {
	display: flex;
	justify-content: space-between;
	width: 130%;
	text-align: center;
	margin-top: 10px;
}
#profileScrollBar button {
	color: #34529e;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 11px;
	width: 25%;
}
#profileContainer {
	overflow-x: hidden;
	background-color: #ffffff;
}
#appB {
	box-shadow: none;
}
#profileAppBarTab {
	color: #34529e;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 11px;
	text-align: center;
}
#profileAppBarTab:focus {
	outline: none;
}
#profileAppBarTab span {
	margin: 0 !important;
}

#profileEdit {
	background-color: #34529e;
	width: max-content;
	padding: 2px;
	border-radius: 50%;
	position: absolute;
	right: 13px;
	top: 103px;
	width: 30px;
	height: 30px;
}
#profileEdit img {
	position: absolute;
	top: 15%;
	left: 15%;
}
#menuVertIcon {
	text-align: right;
	align-self: right;
}
.rec-by {
	font-family: "Montserrat";
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: right;
	color: #34529e;
	position: absolute;
	bottom: 3px;
	right: 9px;
}
.rec-text {
	font-family: "Montserrat";
	font-size: 10px;
	font-style: italic;
	font-weight: 300;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
}
.recContainer {
	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	padding: 6px 13px 25px 13px;
	position: absolute;
	width: 91vw;
	left: 15px;
}
.rateContainer {
	border-bottom: 1px solid #cacaca;
	display: flex;
	flex-direction: column;
}
.RaterName {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;

	color: #34529e;
	text-align: left;
}
.RaterProffesion {
	font-family: "Montserrat";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: left;
}
.RateMain {
	height: 100%;
	width: 100%;
	padding-bottom: 100px;
}
#profileAppBar .css-19kzrtu {
	padding: 0px !important;
}
.RateInner {
	width: 100%;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	padding: 32px 14px;
	padding-bottom: 20px;
}
.hiddenRateContainer {
	margin-left: 24px;
}
.rateButton {
	position: relative;
	/* left: -54px; */
	outline: none;
}
.rateButton:focus {
	outline: none;
}

/* My Posts CSS */
#myPostsContainer {
	height: 44vh;
	overflow-y: scroll;
}
#statusBtnGroup {
	display: flex;
	justify-content: space-evenly;
	font-family: "Montserrat";
	font-size: 9px;
	font-style: normal;
	font-weight: 600;
	line-height: 11px;
	letter-spacing: 0em;
	text-align: center;
	color: white;
	margin-top: 30px;
}
.statusBtns {
	position: relative;
}
.statusBtns label {
	background-color: #b3995b;
	padding: 12px 12px 12px 35px;
}
.statusBtns input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.statusCheckboxSpan {
	position: absolute;
	top: 0;
	left: 10%;
	height: 12px;
	width: 12px;
	background-color: #b3995b;
	border: 1px solid #dadada;
	border-radius: 2px;
}
.statusBtns:hover input ~ .statusCheckboxSpan {
	background-color: #ccc;
}
/* Create the checkmark/indicator (hidden when not checked) */
.statusCheckboxSpan:after {
	content: "";
	position: absolute;
	display: none;
}
.statusBtns input:checked ~ .statusCheckboxSpan:after {
	display: block;
}
.statusBtns .statusCheckboxSpan:after {
	left: 3px;
	width: 4px;
	height: 8px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
