.actions {
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 10px;
/* identical to box height */

text-align: center;
}

svg {
    padding-bottom: 0.25rem;
}