@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");

.LoginLoginDesktop {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	justify-content: center;
	padding: 2% 100px;
	overflow: hidden;
}
.LoginHeader {
	width: 100%;
	overflow: hidden;
}
.LoginRow {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	overflow: hidden;
}
.LoginSectionOne {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-content: center;
	justify-content: flex-start;
	width: 50%;
	margin: 5% 2%;
	overflow: hidden;
}
.LoginHeading {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 40px;
	line-height: 30px;
	text-align: center;
	color: #34529e;
}
.LoginHeadingTwo {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	color: #474747;
	margin: 22px 0%;
}
.LoginSectionOneImage {
	width: 100%;
	height: 100%;
}

.LoginSectionTwo {
	width: 50%;
	margin: 0% 2%;
}
.LoginForm {
	width: 100%;
	padding: 5%;
	background-color: #fff;
	border-radius: 5px;
}
.LoginLabel {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 15px;
	line-height: 30px;
	text-align: left;
	color: #474747;
	width: 100%;
	margin-top: 10px;
}

.LoginInput {
	width: 100%;
	height: 60px;
	background: #f2f2f2;
	border-radius: 5px 5px 0px 0px;
	border-bottom: 3px solid #34529e;
	margin: 10px 0px;
	font-weight: 400;
	margin-bottom: 25px;
	font-style: normal;
	font-size: 13px;
	line-height: 30px;
	text-align: left;
	color: #474747;
	padding: 15px 26px;
}
.LoginInput:focus {
	border: 0px;
}
.LoginForgot {
	font-weight: 700;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	text-align: right;
	color: #34529e;
	margin-bottom: 5px;
}
.LoginButton {
	width: 100%;
	height: 60px;
	background: #34529e;
	border-radius: 5px;
	color: #fff;
	font-family: "Josefin Sans", sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	margin: 50px 0px;
	cursor: pointer;
}

.LoginSignup {
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 18px;
	color: #818181;
	margin-bottom: 30px;
}
.LoginNewAccount {
	color: #34529e;
	font-weight: 700;
	text-decoration: none;
}
