.connect-wrapper {
	background-color: #f6f8fb;
	height: 100%;
	 padding-top: 45px;
}
#connect-headingbar {
	margin-top: 20px;
	background: #ececec;
	height: 27px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px 0 16px;
}
#connect-headingbar p {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
}
#connect-invite-wrap {
	width: 98%;
	margin: 0 auto;
}
#connection-invite-card {
	display: flex;
	justify-content: space-around;
}
#connect-invites-btns-wrapper {
	display: flex;
	align-items: center;
}
#connect-invites-btns {
	padding: unset;
	justify-content: space-around;
	width: 30vw;
	display: flex;
}
#connect-accept-btn {
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	display: flex;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	height: 28px;
	width: 28px;
}
#connect-decline-btn {
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	display: flex;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	height: 28px;
	width: 28px;
}
#connect-accept-btn svg {
	fill: #25da6d;
	height: 15px;
}
#connect-decline-btn svg {
	fill: #c91212;
	height: 15px;
}
#music-connect-wraper {
	display: grid;
	grid-template-columns: 50% 50%;
	width: 95%;
	margin: 0 auto;
	margin-bottom: 75px;
}
#music-connect-avatar {
	margin: 0 auto;
	margin-top: 5px;
	width: 66px;
	height: 66px;
}
#music-connect-button {
	background: #34529e;
	border-radius: 4px;
	padding: 10px 12px 10px 12px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 10px;
}
#music-connect-pendingBtn {
	background: #b2b2b2;
	border-radius: 4px;
	padding: 10px 12px 10px 12px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	color: #ffffff;
	margin-bottom: 10px;
}
#music-connect-heading {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #252525;
}
#music-connect-subline {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	line-height: 11px;
	text-align: center;
	color: #666666;
	margin-bottom: 4px;
}
#music-connect-location {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 8px;
	line-height: 10px;
	text-align: center;
	color: #b3995b;
	margin-bottom: 3px;
}
#music-connect-commons {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 9px;
	line-height: 11px;
	text-align: center;
	color: #34529e;
	margin-bottom: 13px;
}
#connection-invite-head {
	padding: 16px 16px 16px 0;
}
#music-connect-subline {
	height: 8px;
}
#searchBar {
	width: 100vw;
}
#connectsearch {
	width: 80%;
	/* border: 1px solid #34529e; */
	box-sizing: border-box;
	border-radius: 20px;
}
#searchConnectInput {
	border: 1px solid #34529E;
	border-radius: 20px;
	width: 60vw;
	height: 8px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	text-align: left;

	color: #34529e;
	/* padding-right: 10px; */
	padding-left: 20px
}
#connectsearch .MuiInputBase-adornedStart {
	border: 0px;
}
#connectsearch .css-1o2jng6-MuiOutlinedInput-notchedOutline {
	border: 0px;
}
#connectsearch input {
	outline: none;
}
#dataSearchConnect {
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	background-color: white;
	margin: 0px 14px -12px 14px;
}
#dataSearchConnectWrapper {
	position: absolute;
	top: 50px;
	width: 100vw;
	background-color: whitesmoke;
	height: 35.5vh;
	overflow-y: scroll;
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}
#dataSearchConnectWrapperNot {
	display: none;
	position: absolute;
	top: 50px;
	width: 100vw;
	background-color: whitesmoke;
	height: 35.5vh;
	overflow-y: scroll;
	box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}
#dataSearchConnectButtonNot {
	display: none;
}
#dataIconButton {
	padding: 0;
}
#connect-headBrowseBtn {
	color: #34529e;
	font-family: Montserrat;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;
	text-align: center;
}
