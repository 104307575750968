.gig-date {
    color: #B3995B;
}

.apply-btn {
    width: 65%;
    background-color: #34529E;
}

.loader-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}