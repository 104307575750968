#aboutPageContainer {
    position: relative;
    top: 60px;
    text-align: left;
    padding: 2.5vw;
    font-family: Montserrat;

}

#aboutPageFirstSection h1{
    font-weight: 700;
    font-size: 12px;
    color: #303030
}
#aboutPageFirstSection p {
    font-weight: 400;
    font-size: 10px;
    text-align: justify;
    margin-top: 13px;
}
#aboutPageSecondSection h1 {
    font-weight: 700;
    font-size: 12px;
    color: #303030;
    margin-top: 59px;
}
#aboutPageSecondSection p {
    font-weight: 400;
    font-size: 10px;
    text-align: justify;
    margin-top:13px;
}