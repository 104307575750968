.btmNav-wrapper {
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    background-color: white;
    padding: 10px 0 10px 0;
    z-index: 2;
}
.btmNav-wrapper a p {
    font-weight: 400;
    font-family: Montserrat;
    font-size: 10px;
    margin-top: 7px;
}
.btmNav-wrapper a img {
    width: 18px;
    height: 18px;
    margin: 0 auto;
}
#browse-wrap  p{
    margin-top: 0;
}
#browseimg {
    width: 30px;
    height: 30px;
}

#gig-icon{
     width: 20px;
    height: 20px;   
}